.table-entry {
    border-radius: 12px;
    padding: 5px;
    height: 100%;
}

.table-col {
    margin: 12px 0;
}

.table-row {
    background-color: #bb86fc33;
    border-radius: 12px;
    margin: 12px 0;
}