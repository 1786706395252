.my-field {
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  display: flex;
  flex-flow: row wrap;
  padding: 6px 6px;
  max-height: 200px;
  overflow: auto;
}

.up {
  background-color: rgb(18, 18, 18);
  position: absolute;
  top: 0;
  border: 0.07rem solid rgb(206, 212, 218);
  left: 0;
  right: 0;
  z-index: 75;
}

.down {
  z-index: 25;
  padding-right: 24px;
  border: 0.07rem solid #444;
  color: #bb86fc;
  position: relative;
}

.field-div {
  cursor: default;
}

.cross {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  transition: 450ms;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  flex-direction: center;
  cursor: pointer;
}

.cross-text {
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: center;
  transition: 450ms;
}