.message-input {
  height: 150px;
}

.submit-button-div {
  display: flex;
  justify-content: center;
}

.submit-button {
  height: 30px;
  width: 100%;
  background-color: #bb86fc;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button:focus, .submit-button:active {
  background-color: #03dac5;
  box-shadow: 0 0 8px #03dac5!important;
}

.submit-button:hover {
  background-color: #bb86fc99!important;
}

.star {
  margin-left: -4px;
}

.red {
  color: red;
}