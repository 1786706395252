.input-col {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.contest-select-box {
  position: relative;
  margin: 5px 0;
}


.output-col {
  padding: 5px;
  margin-bottom: 9.77px;
}

.branch-select-box {
  position: relative;
  margin: 5px 0;
}

.tag-select-box {
  position: relative;
  margin: 5px 0;
}

.slider-box {
  padding: 10px 20px 0 20px;
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

#contest-box {
  position: relative;
}