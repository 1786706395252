header {
  background-color: #1d1921;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 80px;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  border-radius: 4px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu {
  background-color: #2a2333;
  width: 300px;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  transform: translateX(-300px);
  transition: 850ms;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.nav-menu.active {
  transform: translateX(0);
  transition: 350ms;
}

.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-item a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  border-radius: 4px;
}

.nav-item a:hover {
  background-color: #bb86fc;
  color: black;
  transition: 150ms;
}

.solo-icon {
  color: #ffffff;
  transition: 150ms;
}

.solo-icon:hover {
  color: #bb86fc;
  transition: 150ms;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-toggle {
  background-color: #2a2333;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.menu-logo {
  margin-right: 2rem;
  background: none;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-logo {
  width: 60px;
  border-radius: 30px;
}

.header-left {
  display: flex;
  align-items: center;
}

.page-title {
  font-size: 20px;
  transform: translateX(15px);
}

.header-center {
  position: absolute;
  top: 5px;
  left: 50vw;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  width: 1000px;
  max-width: calc(100vw - 330px);
}

.normal {
  flex-direction: column;
}

.admin {
  flex-direction: row;
}

.switch-div {
  margin: 0 20px 0;
}

.date-form {
  width: 300px;
}

.form-col {
  padding: 4px !important;
}

.header-right {
  display: flex;
  align-items: center;
}

.bulletpoint {
  position: relative;
}