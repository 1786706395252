#endcard-div {
  width: 1920px;
  height: 1080px;
  background-color: #231e29;
  position: relative;
  margin: 10px;
}

#endcard-div * {
  font-family: "Computer Modern";
}

.endcard-box {
  position: absolute;
  background-color: #bb86fc33;
}

.reccomended-video {
  width: 654px;
  height: 380px;
}

#our-reccomendation {
  top: 340px;
  left: 50px;
}

#youtube-suggestion {
  top: 340px;
  right: 50px;
}

#subscribe {
  top: 365px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  height: 330px;
  width: 330px;
}

.endcard-text {
  position: absolute;
  font-size: 40px;
}

#endcard-title {
  font-size: 130px;
  top: 40px;
  max-width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

#our-reccomendation-text {
  top: 278px;
  left: calc(50px + 0.5 * 654px);
  transform: translateX(-50%);
}

#youtube-suggestion-text {
  top: 278px;
  right: calc(50px + 0.5 * 654px);
  transform: translateX(50%);
}

#subscribe-text {
  top: 750px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

#endcard-website {
  font-size: 50px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  white-space: nowrap;
  text-align: center;
}

#website-div {
  font-family: Courier New;
}

#subscribe-arrow {
  position: absolute;
  top: 635px;
  left: 1085px;
  font-size: 110px;
}

#special {
  top: calc(340px + 380px / 2);
  left: calc(50px + 654px / 2);
  transform: translate(-50%, -50%);
  font-size: 60px;
  max-width: 500px;
  text-align: center;
}