.proof-title {
  font-size: 22px;
  margin: 25px 0 10px 0;
  font-style: bold !important;
  border-bottom: 1px solid #9982b5;
}

.proof-title {
  position: relative;
}

.proof-div {
  position: relative;
  padding-bottom: 20px;
}

.proof-qed {
  position: absolute;
  right: 5px;
  bottom: 0px;
}