.lecture-head-background {
    position: relative;
    height: auto;
    width: auto;
    background-color: mediumaquamarine;
    margin: 50px;
}

.slide-div {
    margin: 50px;
    padding: 0px 20px 10px 20px;
    width: 1280px;
    min-height: 150px;
    background-color: antiquewhite;
    background-color: #342941;
}

.slide-header {
    font-size: 30px;
    font-style: bold !important;
    font-family: "Computer Modern Sans", sans-serif;
    border-bottom: 1px solid #9982b5;
    display: flex;
    justify-content: space-between;
}

.slide-header .cheatsheet-title {
    color: #D77F65;
}

.slide-subtitle {
    font-size: 25px;
    margin-left: 30px;
}

#skill-text {
    margin: 8px 0;
    background-color: #342941;
    padding: 20px 0;
    border-radius: 16px;
}

.slide-content {
    font-size: 25px;
}

.problem-title-bg {
    position: relative;
    height: 400px;
    background-color: mediumaquamarine;
    width: 1500px;
    margin: 100px;
}

.problem-title {
    color: #EE4B2B;
    font-weight: 600;
}

.slide-content {
    font-size: 30px;
    margin-top: 2px;
}