.pdf-col {
  display: flex;
  align-items: baseline;
}

.switch-box {
  margin: 10px 0;
  padding: 0 12px;
  width: 300px;
}

.problems-title{
  margin: 16px 16px 0 16px;
  padding: 16px;
}

.info-div {
  font-size: large;
}

@media (min-width: 600px) {
  #options-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#order-div {
  margin: 10px 0;
  padding: 0 12px;
}

.order-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
}

.order-icon-div {
  padding-left: 12px;
}

.order-icon {
  color: var(--primary);
}