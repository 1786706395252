.button {
  background-color: #342941;
  width: 100%;
  margin: 5px 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  transition: 500ms;
  position: relative;
}

.button-container {
  padding: 0 8px;
}

.badge {
  color: #000000;
}

.main-col {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 499px) {
  .button-row:not(.skill) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .badge-col {
    display: inline;
  }

  .expand-icon {
    margin-right: 20px;
  }
}

.main-col-box {
  margin: 0;
  display: flex;
  align-items: center;
  min-width: 70px;
}

.title-box {
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0px;
}

.badge {
  height: 30px;
  width: 45px;
  border-radius: 12px;
  font-size: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-col,
.badges-col .badge-col {
  padding: 0 0 0 0 !important;
}

.badges-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.badge-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-info {
  position: absolute;
  bottom: -10px;
  transform: translateY(100%);
  left: 0px;
  right: 0px;
  z-index: 100;
  background-color: #4b3665;
  border-radius: 16px;
  padding: 5px;
}

.table {
  background-color: #342941;
  border-radius: 16px;
  margin: 8px 0;
  padding: 0 8px;
  overflow: hidden;
}

.icon-box {
  margin: 8px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statement {
  background-color: #342941;
  border-radius: 16px;
  margin: 8px 0;
  padding: 5px;
}

.statement-title {
  text-align: center;
  color: #bb86fc;
  font-size: 30px !important;
}

.statement-text {
  padding: 5px;
}

.dropdown-col {
  padding: 8px;
}

.property-table-row {
  background-color: #bb86fc33;
  border-radius: 16px;
  margin: 8px 0;
}

.table-text {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.dropdown-box {
  background-color: #231e29;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 0px 8px;
  overflow: hidden;
}

.extra-button {
  margin: 8px 0;
}

.title-link {
  text-decoration: none;
  color: white;
}

.title-link:hover {
  color: white;
}

#info-tag-field {
  background-color: #342941;
  border-radius: 16px;
  margin: 8px 0;
  padding: 5px 10px;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-wrap: wrap;
}

#skill-button {
  padding: 0 10px;
}

#skill-title {
  font-size: 25px;
}

#skill-text {
  margin: 8px 0;
  background-color: #342941;
  padding: 20px 0;
  border-radius: 16px;
}

.external-comment {
  margin: 10px 30px;
}

.statement-header {
  position: relative;
  padding: 0 30px;
}

#pdf-share {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-5px, -3px);
}

#aops-share {
  position: absolute;
  top: 0;
  right: 20px;
  transform: translate(-5px, -3px);
}