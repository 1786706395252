.inline-span{
  margin: 0;
}

.block-span {
  overflow-x: auto;
  display: flex;
  align-items: center;
}

.block-inner {
  margin: auto;
}

::-webkit-scrollbar {
  height: 12px; /* At least not zero */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .3); /* Or any other instruction making the element visible */
}