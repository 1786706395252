* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main {
  color: #ffffff;
  margin-top: 80px;
}
@media (max-width: 960px) {
  main {
    min-height: calc(100vh - 129px);
  }
}

@media (min-width: 960px) {
  main {
    min-height: calc(100vh - 111px);
  }
}

@media (min-width: 1200px) {
  .content-page[compr="small"] {
    margin-left: 300px;
    transition: 350ms;
  }
}

@media (min-width: 1200px) {
  .content-page[compr="large"] {
    margin-left: 0px;
    transition: 850ms;
  }
}