.footer {
  width: 100%;
  margin: 0;
  padding: 0 8px 8px 8px;
}

@media (min-width: 960px) {
  .footer {
    display: flex;
    justify-content: space-between;
  }
}

.contact-text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #999;
}

.license-text {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #999;
}