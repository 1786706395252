.switch-element {
  background-color: #342941;
  height: 40px;
  width: 80px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.left {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 30px;
  width: 30px;
  background-color: grey;
  border-radius: 20px;
  transition: 450ms;
}

.right {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
  background-color: #bb86fc;
  border-radius: 20px;
  transform: translateX(40px);
  transition: 450ms;
}

.switch-active {
  border: 2px solid #bb86fc;
  height: 44px;
  width: 84px;
  margin: 0px;
}

.inactive {
  margin: 4px;
}