.problem-tag {
  height: 30px;
  width: 80px;
  border-radius: 12px;
  font-size: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  background-color: #bb86fc;
  color: black;
  text-align: center;
  cursor: pointer;
}

.problem-tag:hover {
  transition: 450ms;
  background-color: #03dac5;
}

.hint {
  color: grey;
  font-weight: 400;
}