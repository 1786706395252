.channel-banner-div {
  margin: 20px;
  width: 2560px;
  height: 1440px;
  background-color: #231e29;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.channel-banner-ctr {
  height: 420px;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.channel-banner-title {
  text-align: center;
  color: #bb86fc;
  font-size: 100px;
  font-family: "Computer Modern";
}

.problem-titles-div {
  padding: 0 30px;
  font-family: "Computer Modern";
  font-size: 12px;
}

.difficulties-div {
  width: 1546px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.difficulty-div {
  font-size: 45px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Style Script";
}

.banner-difficulty-logo {
  margin-right: 18px;
  width: 45px;
  border-radius: 50%;
}