.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-logo {
  width: 20%;
  max-width: 400px;
  min-width: 150px;
  border-radius: 50%;
  margin: 16px;
}

.text-col {
  display: flex;
  padding: 0;
}

.big-button-box {
  display: flex;
  padding: 0;
}


.chart-box {
  display: flex;
  flex-direction: column;
}

.chart-stats {
  font-size: 35px;
  margin-bottom: 5px;
}

.chart {
  margin: 5px;
  height: 300px;
}

span.primary-text {
  padding: 0;
  margin: 0;
}

.chart-col {
    padding: 10px 20px;
}