.my-tooltip .tooltip-inner {
  display: inline-block;
  position: relative;
  text-align: left;
  color: white;
  background-color: black;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0,0,0,1);
  padding: 10px;
  margin: 0 0 1px 0;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
  max-width: 500px;
}