body {
    margin: 0;
    background-color: #121212;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --primary: #bb86fc;

    --elementary: #00BB9E;
    --medium: #F9F871;
    --intermediate: #FFA87E;
    --advanced: #FF7DD8;
    --expert: #C13D25;
    --foundation-course: #4BFCE2;
}

.elementary {
    color: var(--elementary) !important;
}

.medium {
    color: var(--medium) !important;
}

.intermediate {
    color: var(--intermediate) !important;
}

.advanced {
    color: var(--advanced) !important;
}

.expert {
    color: var(--expert) !important;
}

.foundation-course {
    color: var(--foundation-course) !important;
}

.thumbnail-test-img {
    width: 1000px;
}