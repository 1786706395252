.pop-up {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: grey;
  border: solid black 2px;
  border-radius: 6px;
  color: black;
  padding: 4px;
  transition: 300ms;
  width: 100px;
  height: 100px;
  z-index: 10000;
}

.pop-up.active {
  opacity: .7;
}

.pop-up.inactive {
  opacity: 0.7;
}