#comment-div {
  color: #bbb;
}

#comment-div > .primary-text {
  color: #bb98e6;
}

#comment-div > .primary-text:hover {
  color: #ffffff;
  background-color: #bb98e6;
}