.thumbnail-background {
    position: relative;
    height: 800px;
    background-color: mediumaquamarine;
    width: 1500px;
    margin: 100px;
}

.thumbnail-div {
    margin: 50px;
    width: 1280px;
    height: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: antiquewhite;
    overflow: hidden;

    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.thumbnail-div-dark {
    margin: 50px;
    width: 1280px;
    height: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #231e29;
    overflow: hidden;

    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.thumbnail-div * {
    color: #3A3B3C;
}

.thumbnail-div-dark * {
    color: antiquewhite;
}

.thumbnail-div:before {
    content: "";
    background-position: center;
    background-size: cover;
    position: absolute;
    opacity: 0.05;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.thumbnail-div-dark:before {
    content: "";
    background-position: center;
    background-size: cover;
    position: absolute;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}


.thumbnail-title,
.thumbnail-title * {
    text-align: center;
    font-size: 120px;
    margin: 0 0 -20px 0;
    font-family: 'Bebas Neue', sans-serif;


    background: linear-gradient(0deg, #231e29 -30%, #bb86fc 80%, rgba(255, 0, 204, 1) 200%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thumbnail-title-white,
.thumbnail-title-white * {
    text-align: center;
    font-size: 100px;
    margin-top: 15px;
    color: antiquewhite;
    font-weight: bold;
}

.thumbnail-problem .thumbnail-text-div {
    width: 1200px;
    text-align: center;
    font-family: "Computer Modern";
    font-size: 55px;
}

.thumbnail-problem .thumbnail-text-div * {
    font-family: "Computer Modern";
}

.thumbnail-problem .thumbnail-text-div-dark {
    width: 1100px;
    text-align: center;
    font-family: "Computer Modern";
    font-size: 55px;
}

.thumbnail-problem .thumbnail-text-div-dark * {
    font-family: "Computer Modern";
}

.thumbnail-difficulty-background {
    position: absolute;
    right: 740px;
    top: 550px;
    height: 400px;
    width: 800px;
    display: flex;
    align-items: center;

    background-color: #231e29;
    border-radius: 110em 90em 50em 0em / 100em 50em 50em 0em;
}

.thumbnail-difficulty-inner {
    position: absolute;
    left: 15px;
    bottom: 5px;
    display: flex;

}

.thumbnail-difficulty-text {
    font-size: 90px;
    font-family: "Style Script";
}

.thumbnail-difficulty-logo {
    margin: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.background-algebra::before {
    background-image: url("../../img/thumbnail-backgrounds/BackgroundA_p.png");
}

.background-combinatorics::before {
    background-image: url("../../img/thumbnail-backgrounds/BackgroundC_p.png");
}

.background-geometry::before {
    background-image: url("../../img/thumbnail-backgrounds/BackgroundG_p.png");
}

.background-number-theory::before {
    background-image: url("../../img/thumbnail-backgrounds/BackgroundN_p.png");
}

.thumbnail-problem .block-span {
    margin-top: -27px;
    margin-bottom: -33px;
}