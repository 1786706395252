.brand-new-tag {
  background-color: red;
  color: white;
  border: solid black 2pt;
  position: absolute;
  top: 0;
  left: 0;
  
  font-size: 8px;
  padding: 1px 3px;
  border-radius: 5px;

  transform: rotate(-30deg);
}