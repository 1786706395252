.my-text-field {
  background-color: rgb(18, 18, 18);
  border-radius: 6px;
  border: 0.07rem solid rgb(206, 212, 218);
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 75;
  overflow: auto;
  max-height: 300px;
}

.text-input-col {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.page-select-box {
  position: relative;
}

.text-field-div {
  cursor: pointer;
  height: 40px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.normal-text-field {
  padding: 2px;
}

.highlighted-text-field {
  padding: 0;
  border: 2px solid var(--primary) 
}

.inner-text-field-div {
  line-height: 1.0;
  margin: 4px 0;
}